

export function calculateClassification(data: any){

    if(data.finura < 2.5){

        if(data.finura >= 1.5){

            if(data.largo >= 1.5){

                return 'BUENO';

            } else {

                if(data.rendimiento >= 1.5) return 'BUENO';
                
            }
        }

    } else {

       return data.rendimiento < 2.5 ? 'BUENO' : 'EXTRABUENO';

    }

    return 'NORMAL';
}