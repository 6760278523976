const valueNoEspecificado = '-';

export function datePipe(value: any, ...args: any[]) {
    return new Date(value).toLocaleString('es-ES').split(',')[0];
  }

export function sancionesPocaMuchaPipe(value: any, ...args: any[]) {
    return value !== '' ? (value ? 1 : 0) : valueNoEspecificado;
  }

export function sancionesPipe(value: any, ...args: any[]) {
    return value !== ''
      ? value
        ? 1
        : valueNoEspecificado
      : valueNoEspecificado;
  }

export function envasePipe(value: any, ...args: any[]) {
    if (!!value) {
      if (value.saca) {
        return 'Saca de plástico';
      }
      if (value.sacos) {
        return 'Sacos pequeños';
      }
      if (value.balote) {
        return 'Balote';
      }
      if (value.granel) {
        return 'Granel';
      }
    } else {
      return valueNoEspecificado;
    }
  }

export function descontarPipe(value: any, ...args: any[]) {
    if (!!value) {
      if (value.humedad) {
        return 'humedad';
      }
      if (value.piedras) {
        return 'piedras';
      }
      if (value.cascarrias) {
        return 'cascarrias';
      }
      if (value.noclasificable) {
        return 'noclasificable';
      }
      if (value.cadillos) {
        return 'cadillos';
      }
      if (value.pajas) {
        return 'pajas';
      }
      if (value.estiercol) {
        return 'estiércol';
      }
      if (value.otros) {
        return 'otros';
      }
    } else {
      return valueNoEspecificado;
    }
  }

export function descontarLanaPipe(value: any, ...args: any[]) {
    if (!!value) {
      if (value.merina1) {
        return 'merina1';
      }
      if (value.merina2) {
        return 'merina2';
      }
      if (value.merina3) {
        return 'merina3';
      }
      if (value.entrefino1) {
        return 'entrefino1';
      }
      if (value.entrefino2) {
        return 'entrefino2';
      }
      if (value.pelo) {
        return 'pelo';
      }
      if (value.negro) {
        return 'negro/gris';
      }
      if (value.lacon) {
        return 'lacon';
      }
      if (value.campanica) {
        return 'campaniça';
      }
      if (value.corto) {
        return 'corto/añino';
      }
      if (value.amarillo) {
        return 'amarillo';
      }
    } else {
      return valueNoEspecificado;
    }
  }
