import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiCalidadService
{
	constructor(private http: HttpClient) {}

	public getCalidad(idCooperativa: string, idSocio,  idSeason: string, initDate: string, endDate: string): Observable<any>
	{
		let params = new HttpParams().set('idCooperativa', idCooperativa).set('idSocio', idSocio).set('idSeason', idSeason).set('initDate', initDate).set('endDate', endDate);

		return this.http.get(`${environment.serverUrl}calidad/getCalidad`,
		{
			params: params
		}).pipe(map(this.extractData));
	}

	private extractData(res: Response)
	{
		const body = res;
		return body || {};
	}

	private handleError<T>(operation = 'operation', result?: T)
	{
		return (error: any): Observable<T> =>
		{
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.log(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
